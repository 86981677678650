import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import AniLink from "gatsby-plugin-transition-link/AniLink"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404 Not found" />
    <div className="container-fluid flex flex-wrap justify-center items-center">
      <div className="w-full flex text-white flex-col justify-center">
        <h1 className="font-display text-5xl mb-4">NOT FOUND</h1>
        <p className="text-2xl">You just hit a page that doesn&#39;t exist.</p>
        <p className="text-2xl">What did you want to do?</p>
        <a
          href="mailto:hollyelliott@hotmail.com"
          title="Email us"
          className={`
          text-2xl`}
        >
          Email us
        </a>
        <a
          href="tel:+447425145755"
          title="Text us"
          className={`
          text-2xl`}
        >
          Text us
        </a>
        <AniLink
          cover
          to="/welcome"
          bg="#4D6D69"
          entry={{ delay: 0.5 }}
          duration={1}
          className={`
          text-2xl`}
        >
          See our welcome message
        </AniLink>
        <AniLink
          cover
          to="/ceremony"
          bg="#4D6D69"
          entry={{ delay: 0.5 }}
          duration={1}
          className={`
          text-2xl`}
        >
          Find out about the ceremony
        </AniLink>
        <AniLink
          cover
          to="/location"
          bg="#4D6D69"
          entry={{ delay: 0.5 }}
          duration={1}
          className={`
          text-2xl`}
        >
          See the location
        </AniLink>
        <AniLink
          cover
          to="/rsvp"
          bg="#4D6D69"
          entry={{ delay: 0.5 }}
          duration={1}
          className={`
          text-2xl`}
        >
          RSVP
        </AniLink>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
